import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Chart from 'react-apexcharts';

const StatusGraph = () => {
    const BASE_API_URL = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const userNameFromLocation = location.state?.userName || storedUser?.userName || '';
        const userEmailFromLocation = location.state?.userEmail || storedUser?.userEmail || null;
        setUserName(userNameFromLocation);
        setUserEmail(userEmailFromLocation);
    }, [location]);

    const [monthlyJobData, setMonthlyJobData] = useState({
        labels: [],
        datasets: [{
            data: []
        }]
    });

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year

    // Fetch available years from the API or based on the data range
    const fetchAvailableYears = useCallback(async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/availableYears`, {
                params: { userEmail: userEmail }
            });
            return response.data;  // Assuming response contains an array of years
        } catch (error) {
            console.error('Error fetching available years:', error);
            return [];
        }
    }, [userEmail]);

    const [availableYears, setAvailableYears] = useState([]);

    useEffect(() => {
        if (userEmail) {
            fetchAvailableYears().then(years => {
                setAvailableYears(years);
            });
        }
    }, [userEmail, fetchAvailableYears]);

    // Fetch job data based on the selected year
    const fetchMonthlyJobData = useCallback(async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/monthlyJobPercentagesByCompany`, {
                params: { userEmail: userEmail, year: selectedYear }
            });

            const allMonths = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            const jobData = allMonths.map((month, index) => {
                const percentage = response.data[index + 1] || 0;
                return Math.round(percentage);  // Round percentage to a whole number
            });

            setMonthlyJobData({
                labels: allMonths,
                datasets: [{
                    label: 'Job %',
                    backgroundColor: 'skyblue',
                    borderColor: 'black',
                    borderWidth: 1,
                    hoverBackgroundColor: 'skyblue',
                    hoverBorderColor: 'black',
                    data: jobData
                }]
            });
        } catch (error) {
            console.error('Error fetching monthly job data:', error);
        }
    }, [userEmail, selectedYear]);

    useEffect(() => {
        if (userEmail) {
            fetchMonthlyJobData();
        }
    }, [userEmail, selectedYear, fetchMonthlyJobData]);

    // Transform monthlyJobData for the "Distributed Columns" chart
    const distributedColumnsOptions = {
        chart: {
            height: 350,
            type: "bar",
        },
        colors: [
            "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#546E7A", "#26a69a", "#D10CE8"
        ],
        plotOptions: {
            bar: {
                columnWidth: "45%",
                distributed: true,
                endingShape: "rounded"
            }
        },
        dataLabels: {
            enabled: true,  // Enable data labels
            formatter: function (val) {
                return Math.round(val); // Round off the decimal and show as whole number
            }
        },
        series: [
            {
                name: 'Job Percentage',
                data: monthlyJobData.datasets[0]?.data || [] // Use the job data from monthlyJobData
            }
        ],
        xaxis: {
            categories: monthlyJobData.labels || [], // Use the months from monthlyJobData
            labels: {
                style: {
                    colors: [
                        "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#546E7A", "#26a69a", "#D10CE8", "#008FFB", "#00E396", "#FEB019", "#FF4560"
                    ],
                    fontSize: "14px"
                }
            }
        }
    };

    return (
        <Row>
            <Col md={6} xs={12} className="mb-4">
                <Card className="mb-4">
                    <Card.Title as="h4" className='text-center'>Monthly Job Percentages</Card.Title>
                    <Card.Body className="pb-0">
                        {/* Year Selector */}
                        <Form.Group controlId="yearSelect" className="d-flex align-items-center">
                            <Form.Label className="mb-0 me-3">Select Year</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                                className="form-select form-select-sm"
                            >
                                {availableYears.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Display the chart */}
                        {monthlyJobData?.datasets?.[0]?.data.length > 0 ? (
                            <Chart
                                options={distributedColumnsOptions}
                                series={distributedColumnsOptions.series}
                                type={distributedColumnsOptions.chart.type}
                                width="100%"
                                height={350}
                            />
                        ) : (
                            <p>Loading chart data...</p>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

export default StatusGraph;
