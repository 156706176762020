import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../apiClient';

const DeactivateAccountContent = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false); // Track if checkbox is checked
    const [userData, setUserData] = useState(null)
    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setUserData(JSON.parse(user));
        }
    }, []);

    console.log(userData);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the checkbox state
    };
    const handleDeActivate = async () => {
        try {
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "Do you want to deActivate your account? This action cannot be undone!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, DeActivate it!",
            });

            if (result.isConfirmed) {
                // Call the API
                const response = await api.deActivateUser(userData.userEmail);

                console.log(response.data)
                // Show success message

                Swal.fire("Deactivate!", "Your account has been deactivated.", "success");
                localStorage.removeItem('user'); // Remove user from localStorage
                navigate('/'); // Redirect to home page
            }
        } catch (error) {
            // Show error message
            Swal.fire("Error", "Something went wrong while deleting your account.", "error");
            console.error("Error deleting account:", error);
        }
    };

    return (
        <div>
            <h4>Deactivate Account</h4>
            <p>
                Deactivating your account is a temporary action. While your account is deactivated, the following will occur:
            </p>
            <ul>
                <li>Your profile and activity will not be visible to others.</li>
                <li>You will stop receiving notifications and updates.</li>
                <li>You will not be able to apply for jobs or use other features of the platform.</li>
            </ul>

            <h5>Key Information:</h5>
            <p>
                - Deactivation is reversible. You can reactivate your account anytime by logging back in.<br />
                - Your data will remain intact while your account is deactivated.<br />
                - If you are deactivating due to any concerns, consider <a href="/contact-support">contacting our support team</a> for assistance.
            </p>

            <h5>Steps to Deactivate Your Account:</h5>
            <ol>
                <li>Confirm your decision by checking the box below.</li>
                <li>Click the "Deactivate Account" button.</li>
                <li>Your account will be deactivated immediately.</li>
            </ol>

            <form>
                <label>
                    <input type="checkbox" onChange={handleCheckboxChange} required /> I understand that my account will be temporarily deactivated, and I can reactivate it anytime.
                </label>
                <br />
                <Button onClick={handleDeActivate} disabled={!isChecked}>
                    Deactivate Account
                </Button>
            </form>
        </div >
    );
};

export default DeactivateAccountContent;
