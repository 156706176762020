import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VictoryAnimation, VictoryLabel, VictoryPie } from "victory";

export default function CircularProgressBar({ userId, userName }) {

  const BASE_API_URL = process.env.REACT_APP_API_URL;
  
  const [state, setState] = useState({
    percent: 0,
    data: [
      { x: 1, y: 0 },
      { x: 2, y: 100 }
    ]
  });

  useEffect(() => {
    let runCount = 0; // To track the number of times the function is called

    const fetchPercentData = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/userPercentage?userId=${userId}`);
        const percent = response.data;

        setState({
          percent,
          data: [
            { x: 1, y: percent },
            { x: 2, y: 100 - percent }
          ]
        });

        runCount++; // Increment the run count

        if (runCount >= 2) {
          clearInterval(interval); // Clear interval after it runs 2 times
        }
      } catch (error) {
        console.error("Error fetching completion percentage:", error);
      }
    };

    // Set interval to fetch data every 2 seconds
    const interval = setInterval(fetchPercentData, 1000);

    // Call the function once immediately on mount
    fetchPercentData();

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [userId]);  // Re-fetch if userId changes

  const handleButtonClick = () => {
    // Use the history hook to navigate
  };
  return (
    <div style={{ height: "320px" }}>
      <h4 className="text-center">Profile Status</h4>
      <svg viewBox="0 0 400 400" width="100%" height="100%">
        <VictoryPie
          standalone={false}
          animate={{ duration: 1000 }}
          width={400}
          height={400}
          data={state.data}
          innerRadius={120}
          cornerRadius={25}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                const color = datum.y > 30 ? "green" : "red";
                return datum.x === 1 ? color : "transparent";
              }
            }
          }}
        />

        <VictoryAnimation duration={1000} data={state}>
          {(newProps) => (
            <VictoryLabel
              x={200}
              y={200}
              textAnchor="middle"
              verticalAnchor="middle"
              text={`${Math.round(newProps.percent)}%`}
              style={{ fontSize: 45 }}
            />
          )}
        </VictoryAnimation>
      </svg>

      {/* Conditional message below the chart */}
      {state.percent < 100 && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p style={{ fontSize: "18px", color: "red" }}>
            {state.percent}% data you filled,{" "}
            <Link
              to="/candidate-dashboard/profile"
              state={{ userName: userName, userId: userId }}
            >
              complete it now!
            </Link>
          </p>
        </div>
      )}
      {state.percent === 100 && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <p style={{ fontSize: "18px"}}>
            Your profile is 100% Completed
          </p>
        </div>
      )}

    </div>
  );
}
