import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaFilter } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import api from "../../apiClient";
import Pagination from "../../Pagination";
import CustomNavbar from "../CustomNavbar";
import Footer from "../Footer";
import './Company.css';

const JobboxCompanyPage = () => {
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [totalPages, setTotalPages] = useState(0);
  const [companyType, setCompanyType] = useState('');
  const [industryType, setIndustryType] = useState('');
  const [location, setLocation] = useState('');
  const [companyTypes, setCompanyTypes] = useState([]);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();

  const handlePageClick = (data) => {
    const selectedPage = Math.max(0, Math.min(data.selected, totalPages - 1));
    setPage(selectedPage);
    localStorage.setItem('currentCompanyPage', selectedPage);
  };


  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const companyTypesResponse = await api.getCompanyTypes();
        setCompanyTypes(companyTypesResponse.data);

        const industryTypesResponse = await api.getIndustryTypes();
        setIndustryTypes(industryTypesResponse.data);

        const locationsResponse = await api.getLocationBasedCompanies();
        setLocations(locationsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, you can set an error state to display an error message to the user
      }
    };

    fetchFilterData();
  }, []);

  const handleCompanyTypeChange = (e) => {
    const value = e.target.value;
    if (value === 'all') {
      setCompanyType(''); // or whatever the default value you want
    } else {
      setCompanyType(value);
    }
    localStorage.setItem('currentCompanyPage', 0);
  };

  const handleIndustryTypeChange = (e) => {
    const value = e.target.value;
    if (value === 'all') {
      setIndustryType(''); // or whatever the default value you want
    } else {
      setIndustryType(value);
    }
    localStorage.setItem('currentCompanyPage', 0);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    localStorage.setItem('currentCompanyPage', 0);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {

        const storedPage = localStorage.getItem('currentCompanyPage');
        const storedPageSize = localStorage.getItem('currentCompanyPageSize');

        if (storedPage) {
          setPage(Number(storedPage));
        }

        if (storedPageSize) {
          setPageSize(Number(storedPageSize));
        }
        const response = await api.fetchCompanies({
          search,
          page: storedPage ? Number(storedPage) : page,
          pageSize: storedPageSize ? Number(storedPageSize) : pageSize,
          companyType,
          industryType,
          location,
        });
        if (response.data.content.length === 0) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Company not found!"
          });
          setSearch('');
          setLocation('');
        }
        setCompanies(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log("Error fetching data: " + error);
      }
    };
    fetchData();
  }, [search, page, pageSize, companyType, industryType, location]);

  console.log(companies)
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    localStorage.setItem('currentCompanyPage', 0);
  };

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value);
    setPageSize(size);
    setPage(0);
  };

  // const handleClick = (companyId) => {
  //   navigate("/companyPage", { state: { companyId } });
  // };
  const handleClick = (companyId) => {
    const company = companies.find((company) => company.companyId === companyId);
    if (company) {
      const encodedCompanyName = encodeURIComponent(company.companyName); // Encode the company name
      navigate(`/companyPage/companyName/${encodedCompanyName}`, { state: { companyId } });
      // Trigger a page reload after navigating
      // window.location.reload();
      // window.open(`/companyPage/companyName/${encodedCompanyName}?companyId=${companyId}`, '_blank');
    } else {
      console.error("Company not found!");
    }
  };

  const isLastPage = page === totalPages - 1;
  const isPageSizeDisabled = isLastPage;


  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className="custom-navbar-container">
        <CustomNavbar />
      </div>

      <div className='welcome-msg'>
        <div className="d-flex flex-column justify-content-between" style={{ marginLeft: '12px' }} >
          <h3 style={{ paddingTop: '20px', paddingLeft: '12px' }}>Filters <FaFilter style={{color:'purple',fontSize:'20px'}} /></h3>
          <Row
            className="d-flex flex-wrap gx-2"
            style={{
              maxWidth: '100%',
              backgroundColor: '#f4f4f9',
              marginLeft: '10px', marginRight: "10px"
            }}
          >
            <Col
              md={3}
              xs={12}
              className="mb-2"
              style={{
                margin: '5px 0',
              }}
            >
              <label
                htmlFor="companyType"
                className="form-label"
                style={{
                  color: '#6c5b7b',
                  fontWeight: 'bold',
                }}
              >
                Select Company Type:
              </label>
              <select
                id="companyType"
                className="form-select form-select-sm fs-6"
                style={{
                  borderColor: '#6c5b7b',
                  borderRadius: '5px',
                  maxWidth: '100%',
                  minWidth: '150px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                value={companyType}
                onChange={handleCompanyTypeChange}
              >
                <option value="all">All</option>
                {companyTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </Col>
            <Col
              md={3}
              xs={12}
              className="mb-2"
              style={{
                margin: '5px 0',
              }}
            >
              <label
                htmlFor="industryType"
                className="form-label"
                style={{
                  color: '#6c5b7b',
                  fontWeight: 'bold',
                }}
              >
                Select Industry:
              </label>
              <select
                id="industryType"
                className="form-select form-select-sm fs-6"
                style={{
                  borderColor: '#6c5b7b',
                  borderRadius: '5px',
                }}
                value={industryType}
                onChange={handleIndustryTypeChange}
              >
                <option value="all">All</option>
                {industryTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </Col>

            <Col
              md={3}
              xs={12}
              className="mb-2"
              style={{
                margin: '5px 0',
              }}
            >
              <label
                htmlFor="location"
                className="form-label"
                style={{
                  color: '#6c5b7b',
                  fontWeight: 'bold',
                }}
              >
                Search Location:
              </label>
              <input
                type="text"
                id="location"
                style={{
                  height: '40px',
                  borderRadius: '5px',
                  width: '100%',
                  border: '1px solid #ccc',
                  padding: '8px',
                }}
                placeholder="Enter location"
                value={location}
                onChange={handleLocationChange}
              />
            </Col>

            <Col
              md={3}
              xs={12}
              className="mb-2"
              style={{
                margin: '5px 0',
              }}
            >
              <label
                htmlFor="search"
                className="form-label"
                style={{
                  color: '#6c5b7b',
                  fontWeight: 'bold',
                }}
              >
                Search Company:
              </label>
              <input
                style={{
                  height: '40px',
                  borderRadius: '5px',
                  width: '100%',
                  border: '1px solid #ccc',
                  padding: '8px',
                }}
                type="text"
                name="search"
                id="search"
                placeholder="Search Company By Name"
                value={search}
                onChange={handleSearchChange}
              />
            </Col>
          </Row>

          <Row
            className="cards flex-grow-1 d-flex flex-wrap justify-content-center"
            style={{
              minHeight: "fit-content",
              width: "100%",
              marginLeft: "0px",
            }}
          >
            {companies.length > 0 ? (
              companies.map((company) => ( // Display only 6 cards per page
                <Col
                  xs={12} // Full width on extra small screens
                  sm={6} // Half width on small screens
                  md={4} // One-third width on medium screens
                  className="d-flex justify-content-start mb-4" // Add spacing between rows
                  key={company.companyId}
                >
                  <Card
                    className="company-card-job"
                    style={{
                      width: "100%",
                      maxWidth: "380px",
                    }}
                  >
                    <Card.Body>
                      <Row
                        className="align-items-start"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Company Name */}
                        <Col xs={6} className="text-start">
                          <Card.Title>
                            <b>{company.companyName}</b>
                          </Card.Title>
                        </Col>
                        {/* Company Logo */}
                        <Col xs={6} className="text-end">
                          <img
                            src={
                              company.logo
                                ? `data:image/png;base64,${company.logo}`
                                : "https://static.vecteezy.com/system/resources/previews/013/899/376/original/cityscape-design-corporation-of-buildings-logo-for-real-estate-business-company-vector.jpg"
                            }
                            alt={`${company.companyName} logo`}
                            style={{
                              maxWidth: "100px",
                              height: "auto",
                              objectFit: "contain",
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Card.Text>
                        Industry: <b>{company.industryService}</b>
                      </Card.Text>
                      <Button onClick={() => handleClick(company.companyId)}>View</Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-bubble spinner-bubble-primary m-5" />
                <span>Loading...</span>
              </div>
            )}
          </Row>

        </div>
        {/* </div> */}
        {companies.length > 0 && (
          <Pagination
            page={page}
            pageSize={pageSize}
            totalPages={totalPages}
            handlePageSizeChange={handlePageSizeChange}
            isPageSizeDisabled={isPageSizeDisabled}
            handlePageClick={handlePageClick}
          />
        )}
      </div>
      <div >
        <Footer />
      </div>
    </div>
  );
};

export default JobboxCompanyPage;
