import { Delete } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

const Jobpreference = ({ userId }) => {
    const BASE_API_URL = process.env.REACT_APP_API_URL;
    const [preferences, setPreferences] = useState({
        preference1: "",
        preference2: "",
        preference3: "",
        preference4: "",
        userId: userId,
        dreamCompanies: "", // Comma-separated string
        notToApplyCompanies: "" // Comma-separated string
    });

    // Arrays to hold companies before converting to string
    const [dreamCompaniesArray, setDreamCompaniesArray] = useState([]);
    const [notToApplyCompaniesArray, setNotToApplyCompaniesArray] = useState([]);

    const [newDreamCompany, setNewDreamCompany] = useState('');
    const [newNotToApplyCompany, setNewNotToapplyCompany] = useState('');

    const [isEditable, setIsEditable] = useState(false); // To track if the inputs should be editable
    const [isDataFetched, setIsDataFetched] = useState(false); // To track if data is fetched

    // Fetch preferences when the component mounts
    useEffect(() => {
        fetchPreferences();
    }, [userId]);

    const fetchPreferences = async () => {
        try {
            const response = await axios.get(`${BASE_API_URL}/getJobPreference`, {
                params: { userId: userId } // Pass userId as query parameter
            });

            if (response.status === 200 && response.data) {
                setPreferences(response.data);
                // Convert comma-separated strings back into arrays for editing
                setDreamCompaniesArray(response.data.dreamCompanies.split(',').filter(Boolean));
                setNotToApplyCompaniesArray(response.data.notToApplyCompanies.split(',').filter(Boolean));
                setIsDataFetched(true);
            } else {
                setIsDataFetched(false); // No data found
            }
        } catch (error) {
            console.error("Error fetching preferences:", error);
            setIsDataFetched(true); // No data fetched
        }
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPreferences({
            ...preferences,
            [name]: value,
        });
    };

    // Handle adding a company to the array (for dreamCompanies or notToApplyCompanies)
    const handleAddCompany = (type) => {
        if (type === "dreamCompanies") {
            //          newCompany = preferences.dreamCompanies.trim();
            if (newDreamCompany && !dreamCompaniesArray.includes(newDreamCompany)) {
                setDreamCompaniesArray([...dreamCompaniesArray, newDreamCompany]);
                setNewDreamCompany('');
            }
        } else if (type === "notToApplyCompanies") {
            // newCompany = preferences.notToApplyCompanies.trim();
            if (newNotToApplyCompany && !notToApplyCompaniesArray.includes(newNotToApplyCompany)) {
                setNotToApplyCompaniesArray([...notToApplyCompaniesArray, newNotToApplyCompany]);
                setNewNotToapplyCompany('');
            }
            setNewDreamCompany('');
            setNewNotToapplyCompany('');
        }
        // Clear input after adding
        setPreferences({
            ...preferences,
            [type]: ""
        });
    };
    // Handle removing company from the respective array
    const handleRemoveCompany = (type, index) => {
        if (type === "dreamCompanies") {
            const updatedArray = [...dreamCompaniesArray];
            updatedArray.splice(index, 1); // Remove company at index
            setDreamCompaniesArray(updatedArray); // Update state with new array
        } else if (type === "notToApplyCompanies") {
            const updatedArray = [...notToApplyCompaniesArray];
            updatedArray.splice(index, 1); // Remove company at index
            setNotToApplyCompaniesArray(updatedArray); // Update state with new array
        }
    };
    // Handle submit form
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate if any preference field is empty
        if (!preferences.preference1 || !preferences.preference2 || !preferences.preference3 || !preferences.preference4) {
            toast.error("Please fill in all preferences before submitting.");
            return; // Prevent form submission if any field is empty
        }

        // Convert arrays to comma-separated strings
        const dreamCompaniesStr = dreamCompaniesArray.join(',');
        const notToApplyCompaniesStr = notToApplyCompaniesArray.join(',');

        try {
            const response = await axios.post(`${BASE_API_URL}/jobPreference`, {
                ...preferences,
                dreamCompanies: dreamCompaniesStr,
                notToApplyCompanies: notToApplyCompaniesStr
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                console.log("Preferences submitted successfully!");
                toast.success("Preferences submitted successfully!");
                setPreferences({
                    preference1: "",
                    preference2: "",
                    preference3: "",
                    preference4: "",
                    userId: userId,
                    dreamCompanies: "",
                    notToApplyCompanies: ""
                });
                setDreamCompaniesArray([]);
                setNotToApplyCompaniesArray([]);
                setIsEditable(false); // Disable editing after submit
                fetchPreferences();
            } else {
                console.error("Error submitting preferences");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Error submitting preferences!");
        }
    };

    // Handle enabling the editing of preferences
    const handleEdit = () => {
        setIsEditable(true); // Enable the inputs for editing
    };

    return (
        <>
            <h4>Job preference:</h4>
            <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <label htmlFor="preference1" className="form-label">1st Preference</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="preference1"
                            name="preference1"
                            value={preferences.preference1}
                            onChange={handleChange}
                            placeholder="Enter 1st preference"
                            disabled={!isEditable}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="preference2" className="form-label">2nd Preference</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="preference2"
                            name="preference2"
                            value={preferences.preference2}
                            onChange={handleChange}
                            placeholder="Enter 2nd preference"
                            disabled={!isEditable}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <label htmlFor="preference3" className="form-label">3rd Preference</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="preference3"
                            name="preference3"
                            value={preferences.preference3}
                            onChange={handleChange}
                            placeholder="Enter 3rd preference"
                            disabled={!isEditable}
                        />
                    </div>
                    <div className="col-sm-6">
                        <label htmlFor="preference4" className="form-label">4th Preference</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id="preference4"
                            name="preference4"
                            value={preferences.preference4}
                            onChange={handleChange}
                            placeholder="Enter 4th preference"
                            disabled={!isEditable}
                        />
                    </div>
                </div>


                <p><strong>Example:</strong>Preferences you can add for AI job matching Jobs like Job Title,Salary,Location,Area of work.</p>
                <ul>
                    <li><strong>Job Title:</strong> The role you want to work in, such as Software Developer, Backend Developer, etc.</li>
                    <li><strong>Location:</strong> The place you want to work, such as India (Bangalore, Hyderabad), USA, etc.</li>
                    <li><strong>Salary:</strong> The desired salary range, such as 0-3 LPA, 3-5 LPA, etc.</li>
                    <li><strong>Area of Work:</strong> The domain you prefer to work in, such as IT, Non-IT, Sales, etc.</li>
                </ul>
                {/* Show Dream Companies */}
                <div className="mb-3 row">
                    <div className="col-sm-6">
                        <label htmlFor="dreamCompanies" className="form-label">Dream Companies</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="dreamCompanies"
                                name="dreamCompanies"
                                value={newDreamCompany}
                                onChange={(e) => setNewDreamCompany(e.target.value)}
                                placeholder="Enter dream companies"
                                disabled={!isEditable} // Disable input if not editable
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => handleAddCompany("dreamCompanies")}
                                disabled={!isEditable}
                            >
                                Add
                            </button>
                        </div>
                        <div className="mt-2">
                            {dreamCompaniesArray.length > 0 && (
                                <div>
                                    <div>Companies Added: </div>
                                    <div className="d-flex flex-wrap">
                                        {/* Loop through the dream companies */}
                                        {dreamCompaniesArray.map((company, index) => (
                                            <div key={index} className="d-flex align-items-center me-3 mb-2">
                                                <span className="me-2">
                                                    {company}
                                                    {/* Delete Icon */}
                                                    <i
                                                        className="fas fa-times text-danger ms-2"
                                                        onClick={() => handleRemoveCompany("dreamCompanies", index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '1rem', // Adjust size of the delete icon
                                                        }}
                                                    >
                                                        <Delete />
                                                    </i>
                                                </span>
                                                {/* Add comma only if it's not the last element */}
                                                {index < dreamCompaniesArray.length - 1 && <span>, </span>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>

                    {/* Show Not to Apply Companies */}
                    <div className="col-sm-6">
                        <label htmlFor="notToApplyCompanies" className="form-label">Not To Apply Companies</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="notToApplyCompanies"
                                name="notToApplyCompanies"
                                value={newNotToApplyCompany}
                                onChange={(e) => setNewNotToapplyCompany(e.target.value)}
                                placeholder="Enter companies to avoid"
                                disabled={!isEditable} // Disable input if not editable
                            />
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => handleAddCompany("notToApplyCompanies")}
                                disabled={!isEditable}
                            >
                                Add
                            </button>
                        </div>
                        <div className="mt-2">
                            {notToApplyCompaniesArray.length > 0 && (
                                <div>
                                    <div>Companies Added: </div>
                                    <div className="d-flex flex-wrap">
                                        {/* Loop through companies and separate them by comma */}
                                        {notToApplyCompaniesArray.map((company, index) => (
                                            <div key={index} className="d-flex align-items-center me-3 mb-2">
                                                <span className="me-2">
                                                    {company}
                                                    {/* Delete Icon */}
                                                    <i
                                                        className="fas fa-times text-danger ms-2" // ms-2 for margin between company and icon
                                                        onClick={() => handleRemoveCompany("notToApplyCompanies", index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '1rem',
                                                        }}
                                                    >
                                                        <Delete />
                                                    </i>
                                                </span>
                                                {/* Display comma only if it's not the last element */}
                                                {index < notToApplyCompaniesArray.length - 1 && <span>,</span>}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>


                {/* Show Edit button only if data is fetched and no edits have been made */}
                {isDataFetched && !isEditable && (
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleEdit}
                    >
                        Edit Preferences
                    </button>
                )}

                {/* Submit button always visible */}
                <button type="submit" className="btn btn-primary">
                    {isEditable ? "Submit Preferences" : "Submit Preferences"}
                </button>
            </form>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        </>
    );
};

export default Jobpreference;
