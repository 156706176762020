import { faBriefcaseClock, faCog, faGraduationCap, faLink, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Tab, Tabs } from 'react-bootstrap';
import { FaBriefcase, FaPlus } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Footer from '../../pages/Footer';
import './CandidateDashboard.css';
import DashboardLayout from './DashboardLayout';
import Jobpreference from './Jobpreference';
import SocialProfileLinks from './SocialProfileLinks';
const Profile = () => {
  const location = useLocation();
  const userId = location.state?.userId;
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const [skills, setSkills] = useState([]);
  const [showSkillsMessage, setShowSkillsMessage] = useState(false);
  const [newName, setNewName] = useState('');
  const [dropdowns, setDropdowns] = useState({
    skills: false,
    education: false,
    experience: false,
  });
  const [educationDetails, setEducationDetails] = useState({
    degree: '',
    branch: '',
    college: '',
    percentage: ''
  });
  const [experience, setExperience] = useState({
    isExperience: '',
    yearsOfExperience: '',
    lastCompany: '',
    jobRole: '',
    jobDescription: ''
  });

  const [isExperience, setIsExperience] = useState(false); // Track if user selects "experience" or "fresher"
  const [originalExperience, setOriginalExperience] = useState({});
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const [formData, setFormData] = useState({
    skills: '',
    educationDetails: {},
    experience: {}
  });

  const [activeTab, setActiveTab] = useState('personal'); // Default tab is 'personal'

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/getCandidate?userId=${userId}`);
        console.log('API Response:', response.data); // Log the response to inspect it
        setUserData(response.data);
        setEducationDetails(response.data.educationDetails || {});
        setExperience(response.data.experience || {});
        setOriginalExperience(response.data.experience || {}); // Set original experience data

        const skillsArray = response.data.skills ? response.data.skills.split(',').map(skill => skill.trim()) : [];
        setSkills(skillsArray);

        setFormData({
          skills: skillsArray,
          educationDetails: response.data.educationDetails || {},
          experience: response.data.experience || {}
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [userId]);


  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      skills,
      educationDetails,
      experience
    }));
  }, [skills, educationDetails, experience]);

  const toggleDropdown = (section) => {
    setDropdowns({ ...dropdowns, [section]: !dropdowns[section] });
  };

  const handleEducationChange = (e) => {
    const { name, value } = e.target;
    setEducationDetails({ ...educationDetails, [name]: value });
  };
  const handleExperienceChange = (e) => {
    const { name, value } = e.target;
    setExperience((prevExperience) => ({
      ...prevExperience,
      [name]: value,
    }));
  };

  // Handle radio button changes and store the value in localStorage
  const handleExperienceRadioChange = (e) => {
    const isSelectedExperience = e.target.value === 'experience';
    setIsExperience(isSelectedExperience);
    localStorage.setItem('isExperience', JSON.stringify(isSelectedExperience));  // Persist the value in localStorage
    if (isSelectedExperience) {
      // Restore the original experience data when switching back to "Experience"
      setExperience(originalExperience);
    } else {
      // Clear the experience data when selecting "Fresher"
      setExperience({
        isExperience: '',
        yearsOfExperience: '',
        lastCompany: '',
        jobRole: '',
        jobDescription: '',
      });
    }
  };
  const [newSkill, setNewSkill] = useState('');

  const handleAddSkill = () => {
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
      setNewSkill(''); // Clear the input field
    }
  };

  const handleRemoveSkill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    try {

      if (skills.length < 5) {
        setShowSkillsMessage(true); // Show message if fewer than 5 skills
        return;
      }
      if (isExperience) {
        // Validate experience fields
        const { yearsOfExperience, lastCompany, jobRole, jobDescription } = experience;
        if (!yearsOfExperience || !lastCompany || !jobRole || !jobDescription) {
          setErrorMessage('Please fill all the details');
          return;
        } else {
          setErrorMessage(''); // Clear error message if all fields are filled
        }
      }

      const skillsString = skills.join(', '); // Convert array to comma-separated string
      const updatedFormData = {
        ...formData,
        skills: skillsString,
        experience: {
          ...formData.experience,
          isExperience: isExperience // Include the isExperience value
        }
      };
      await axios.put(`${BASE_API_URL}/updateCandidateDetails`, updatedFormData, {
        params: { userId }
      });
      toast.success("Details updated successfully!");
    } catch (error) {
      console.error('Error updating user data:', error);
      toast.error('Error updating details');
    }

  };

  const toggleSettings = () => {
    navigate('/');
  };
  // Example function to simulate updating user data
  const handleEditName = async () => {
    // Here you would typically make an API call to update the user data
    try {
      const response = await axios.put(`${BASE_API_URL}/updateCandidate?userId=${userId}&newName=${newName}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  useEffect(() => {
    const savedExperienceStatus = localStorage.getItem('isExperience');
    if (savedExperienceStatus !== null) {
      setIsExperience(JSON.parse(savedExperienceStatus));
    }
  }, []);



  return (
    <DashboardLayout>
      <div className="main-content">
        <Col md={4}>
          <h2 className='text-start' style={{ paddingLeft: '12px' }}>Profile</h2>
        </Col>
        <Tabs
          className='profile-tabs'
          defaultActiveKey="personal" // Set the default active tab
          id="controlled-tab-example"
          activeKey={activeTab} // Control the active tab with state
          onSelect={(key) => setActiveTab(key)} // Update state on tab change
        >
          <Tab eventKey="personal" title={<><FontAwesomeIcon icon={faUser} /> Personal Details</>}></Tab>

          <Tab eventKey="education" title={<><FontAwesomeIcon icon={faGraduationCap} /> Education Details</>}></Tab>

          <Tab eventKey="skills" title={<><FontAwesomeIcon icon={faCog} /> Skills</>}></Tab>

          <Tab eventKey="experience" title={<><FaBriefcase /> Experience</>}></Tab>

          <Tab eventKey="jobpreference" title={<><FontAwesomeIcon icon={faBriefcaseClock} /> Job Preference</>}></Tab>
          <Tab eventKey="social_links" title={<><FontAwesomeIcon icon={faLink} /> Social Profile Links</>}></Tab> {/* New Tab */}

        </Tabs>


        {activeTab === 'personal' && (
          <>
            <div className="candidate-profile-details">
              <h4>Personal details:</h4>
              <div className="profile-container">
                {userData && (
                  <>
                    <div className="profile-item">
                      <span className="profile-label">Name:</span>
                      <span className="profile-value">
                        {userData.userName ? (
                          userData.userName
                        ) : (
                          <>
                            <input
                              type="text"
                              value={newName}
                              onChange={(e) => setNewName(e.target.value)}
                              placeholder="Enter your name"
                            />
                            <button onClick={handleEditName}>Edit Name</button>
                          </>
                        )}
                      </span>
                    </div>
                    <div className="profile-item">
                      <span className="profile-label">Email:</span>
                      <span className="profile-value">{userData.userEmail}</span>
                    </div>
                    <div className="profile-item">
                      <span className="profile-label">Phone Number:</span>
                      <span className="profile-value">{userData.phone}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {/* Skills Tab */}
        {activeTab === 'skills' && (
          <>
            <div className="candidate-profile-details">
              <h4>Skills:</h4>
              <p className="text-danger mb-3">(Note: Add at least 5 skills)</p>
              <div className="mb-3 d-flex flex-wrap">
                {skills.map((skill, index) => (
                  <span
                    key={index}
                    className="badge  text-dark me-2 mb-2 position-relative d-inline-flex align-items-center"
                    style={{
                      fontSize: '1rem',
                      padding: '0.5rem 1rem',
                      borderRadius: '0.25rem',
                      border: '1px solid grey'
                    }}
                  >
                    {skill}
                    <i
                      className="fas fa-times text-danger"
                      onClick={() => handleRemoveSkill(index)}
                      style={{
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        marginLeft: '0.5rem', // Add margin to create a gap
                      }}
                    >
                      <Delete />
                    </i>
                  </span>
                ))}
              </div>


              <div className="d-flex align-items-center">
                <input
                  type="text"
                  value={newSkill}
                  onChange={(e) => setNewSkill(e.target.value)}
                  placeholder="Add a new skill"
                  className="form-control me-2"
                  style={{ width: '300px' }}
                />
                <Button variant="primary" onClick={handleAddSkill}><FaPlus /></Button>
              </div>
              {showSkillsMessage && (
                <p className="text-danger mt-3">Please add at least 5 skills.</p>
              )}
            </div>
          </>
        )}


        {/* Education Details Tab */}
        {activeTab === 'education' && (
          <>
            <div className="candidate-profile-details">
              <h4>Education Details:</h4>
              <div className="container mt-3">
                <div className="row mb-3">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <div className="form-group">
                      <label htmlFor="degree"><b>Last Education Degree:</b></label>
                      <input
                        type="text"
                        id="degree"
                        name="degree"
                        className="form-control"
                        value={educationDetails.degree}
                        onChange={handleEducationChange}
                        placeholder="Enter your last education degree"
                      />
                    </div>
                  </div>

                  <div className=" col-md-6">
                    <div className="form-group">
                      <label htmlFor="branch"><b>Branch Name:</b></label>
                      <input
                        type="text"
                        id="branch"
                        name="branch"
                        className="form-control"
                        value={educationDetails.branch}
                        onChange={handleEducationChange}
                        placeholder="Enter branch name"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <div className="form-group">
                      <label htmlFor="percentage"><b>Percentage/CGPA:</b></label>
                      <input
                        type="text"
                        id="percentage"
                        name="percentage"
                        className="form-control"
                        value={educationDetails.percentage}
                        onChange={handleEducationChange}
                        placeholder="Enter percentage or CGPA"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="college"><b>College/University:</b></label>
                      <input
                        type="text"
                        id="college"
                        name="college"
                        className="form-control"
                        value={educationDetails.college}
                        onChange={handleEducationChange}
                        placeholder="Enter college or university name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* Experience Section */}
        {activeTab === 'experience' && (
          <>
            <div className="candidate-profile-details">
              <h4>Experience:</h4>
              <p className="text-danger mb-3">
                Note: The following information is not required for freshers.
              </p>

              {/* Radio buttons for selecting fresher or experience */}
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="fresher"
                  name="experienceStatus"
                  value="fresher"
                  checked={!isExperience}
                  onChange={handleExperienceRadioChange}
                />
                <label className="form-check-label" htmlFor="fresher">
                  Fresher
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="experience"
                  name="experienceStatus"
                  value="experience"
                  checked={isExperience}
                  onChange={handleExperienceRadioChange}
                />
                <label className="form-check-label" htmlFor="experience">
                  Experience
                </label>
              </div>

              {/* Experience Form */}
              <div className="container mt-3">
                <div className="row mb-3">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <div className="form-group">
                      <label htmlFor="yearsOfExperience">
                        <b>How many years of experience do you have? <span className="text-danger">*</span></b>
                      </label>
                      <input
                        id="yearsOfExperience"
                        type="text"
                        className="form-control form-control-sm"
                        name="yearsOfExperience"
                        value={experience.yearsOfExperience}
                        onChange={handleExperienceChange}
                        placeholder="Your experience"
                        required={isExperience}
                        disabled={!isExperience} // Disable if fresher
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="lastCompany">
                        <b>Last Company <span className="text-danger">*</span></b>
                      </label>
                      <input
                        id="lastCompany"
                        type="text"
                        className="form-control form-control-sm"
                        name="lastCompany"
                        value={experience.lastCompany}
                        onChange={handleExperienceChange}
                        placeholder="Your last company"
                        required={isExperience}
                        disabled={!isExperience} // Disable if fresher
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <div className="form-group">
                      <label htmlFor="jobRole">
                        <b>Job Role <span className="text-danger">*</span></b>
                      </label>
                      <input
                        id="jobRole"
                        type="text"
                        className="form-control form-control-sm"
                        name="jobRole"
                        value={experience.jobRole}
                        onChange={handleExperienceChange}
                        placeholder="Your job role"
                        required={isExperience}
                        disabled={!isExperience} // Disable if fresher
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="jobDescription">
                        <b>Job Description <span className="text-danger">*</span></b>
                      </label>
                      <textarea
                        id="jobDescription"
                        className="form-control form-control-sm"
                        name="jobDescription"
                        value={experience.jobDescription}
                        onChange={handleExperienceChange}
                        placeholder="Your job description"
                        rows="4"
                        required={isExperience}
                        disabled={!isExperience}
                      />
                    </div>
                  </div>
                </div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>} {/* Display error message */}

              </div>
            </div>
          </>
        )}

        {/* Job prefrence Tab */}
        {activeTab === 'jobpreference' && (
          <>
            <div className="candidate-profile-details">
              <Jobpreference userId={userId} />
            </div>
          </>
        )
        }

        {/* Social Profile Links Tab */}
        {activeTab === 'social_links' && (
          <>
            <SocialProfileLinks state={{ userId: userId }} />
          </>
        )}

        <div className="d-flex justify-content-center mt-3">
          {['personal', 'education', 'skills', 'experience'].includes(activeTab) && (
            <Button variant="primary" onClick={handleSave}>
              Save
            </Button>
          )}
        </div>



        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
        <br></br>
      </div >
      <Footer />
    </DashboardLayout >
  );
};
export default Profile;
