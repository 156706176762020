import bcrypt from 'bcryptjs'; // Import bcryptjs for password hashing and comparison
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, ToastContainer } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import api from '../apiClient';

const PersonalContent = () => {
    const [userData, setUserData] = useState(null)
    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            setUserData(JSON.parse(user));
        }
    }, []);

    console.log(userData);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    // State to store password match error message
    const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [passwordPattern, setPasswordPattern] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const togglePasswordVisibility = (type) => {
        if (type === 'old') {
            setShowOldPassword(!showOldPassword);
        } else if (type === 'new') {
            setShowNewPassword(!showNewPassword);
        } else if (type === 'confirm') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the old password is correct
        const passwordMatch = await bcrypt.compare(oldPassword, userData.password);
        console.log(userData.password)
        if (!passwordMatch) {
            setPasswordMatchMessage('Please check your old password!');
            return;
        }

        // Check if the old password and new password are the same
        if (oldPassword === newPassword) {
            setPasswordCheck('Old password and new password should not be the same!');
            return;
        }

        // Check if the new password follows the rules
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
        if (!passwordPattern.test(newPassword)) {
            setPasswordPattern('Password should contain at least 1 capital letter, 1 small letter, 1 digit, and 1 special character, and be between 8 to 15 characters long.');
            return;
        }

        // Check if the new password and confirm password match
        if (newPassword !== confirmPassword) {
            setPasswordConfirm('New password and confirm password should be the same!');
            return;
        }

        // If all conditions are passed, proceed to update the password
        const updatedUser = {
            userId: userData.userId,
            password: newPassword,
            // You can add other fields like skills, education, etc., if you want to update them too.
        };

        try {
            // Make the PUT request to update the password
            const response = await api.updateUserDetails(updatedUser,userData.userId)

            console.log(response.data); // You can handle success logic here


            setSuccessMessage('Password updated successfully!'); // Show success toast
            const updatedUserData = response.data;
            setUserData(updatedUserData); // Update state with the ne

            // Update localStorage with the new user data
            localStorage.setItem('user', JSON.stringify(updatedUserData));
        } catch (error) {
            console.error('Error updating password:', error);
            alert('Failed to update password!'); // Show error toast
        }
    };

    return (
        <div>
            {userData ? (
                <div>
                    <h4><strong>Personal Information</strong></h4>
                    <p><strong>Name:</strong> {userData.userName}</p>
                    <p><strong>Email:</strong> {userData.userEmail}</p>
                    <p><strong>Phone:</strong> {userData.phone}</p>

                    <h5><strong>Change Password:</strong></h5>
                    <Row>
                        <Col md={6}>
                            <Card body className="mb-5">
                                <form>
                                    <Form.Group controlId="oldPassword">
                                        <Form.Label>Enter Old Password</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type={showOldPassword ? 'text' : 'password'}
                                                placeholder="Enter Old Password"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                required
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => togglePasswordVisibility('old')}
                                                >
                                                    {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {passwordMatchMessage && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>{passwordMatchMessage}</div>
                                    )}
                                                           <Form.Group controlId="newPassword">
                                        <Form.Label>Enter New Password</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type={showNewPassword ? 'text' : 'password'}
                                                placeholder="Enter New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => togglePasswordVisibility('new')}
                                                >
                                                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {passwordCheck && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>{passwordCheck}</div>
                                    )}
                                      {passwordPattern && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>{passwordPattern}</div>
                                    )}        
                                    <Form.Group controlId="confirmPassword">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <div className="input-group">
                                            <Form.Control
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                placeholder="Confirm New Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => togglePasswordVisibility('confirm')}
                                                >
                                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {passwordConfirm && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>{passwordConfirm}</div>
                                    )}       
                                    <br></br>
                                    <Button variant="primary" onClick={handleSubmit}>
                                        Update Password
                                    </Button>
                                    {successMessage && (
                                        <div style={{ color: 'green', marginTop: '5px' }}>{successMessage}</div>
                                    )}    
                                </form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            ) : (
                <p>No personal information available.</p>
            )}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />

        </div>
    );
};

export default PersonalContent;
