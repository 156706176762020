import React, { useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../apiClient';

const ActivataionAccount = ({ userEmail }) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(true);
  const [enterOtpValue, setEnterOtpValue] = useState('');
  const [otp, setOtp] = useState();
  const [errorMessageModal, setErrorMessageModal] = useState('');
  const [otpSentMessage, setOtpSentMessage] = useState('');
  const [otpVerificationMessage, setOtpVerificationMessage] = useState('');
  // const [userEmail, setUserEmail] = useState(""); // To hold user email input value
  const [stage, setStage] = useState('email');

  const handleCloseModal = () => {
    setShowModal(false);
    // setUserEmail("");
  };

  const handleSendOTP = async () => {
    if (!userEmail) {
      alert("Please enter an email first.");
      return;
    }
    try {
      const response = await api.otpGenerate(userEmail);
      if (response.data) {
        setOtp(response.data);
        setStage('otp');
        setOtpSentMessage('OTP sent successfully to your email!');
      }
      console.log('OTP sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP. Please try again.');
    }
  };

  const handleOTPVerification = () => {
    console.log("entered otp", enterOtpValue);
    if (enterOtpValue == otp) {
      setStage('success');
      setOtpVerificationMessage("OTP verification successful!");

    } else {
      setErrorMessageModal("Invalid OTP. Please try again.");
    }
  };

  const handleActive = async () => {
    const activeUserResponse = await api.activeUser(userEmail);
    console.log("User  Activated:", activeUserResponse.data);
    const userRole = activeUserResponse.data.userRole
    Swal.fire({
      icon: 'success',
      title: 'Account Activated!',
      text: 'Your account has been successfully activated.',
      confirmButtonText: 'OK',
    }).then(() => {
      handleCloseModal();  // Ensure modal closes before navigating

      // Get the redirect value from localStorage
      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');

      if (redirectAfterLogin === 'candidate-signup' || redirectAfterLogin === 'hr-signup' || redirectAfterLogin === 'forget-password') {
        // If the user came from signup, navigate to the appropriate sign-in page
        if (userRole === 'Candidate') {
          navigate('/signin');
        } else if (userRole === 'HR') {
          navigate('/hr-sign-in');
        }
      } else if (redirectAfterLogin === 'signin' || redirectAfterLogin === 'hr-sign-in') {
        // If the user came from signin, reload the page to refresh the session
        window.location.reload();
      }

      // Clear the redirect info from localStorage after use
      localStorage.removeItem('redirectAfterLogin');
    });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Account Activation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b style={{ color: 'purple' }}>Your email already exists. Please activate your account.</b>
          "Verify your Email first and then Activate your account"
        </p>
        <FormControl
          type="email"
          value={userEmail}
          readOnly
          className="mb-2"
          disabled
        />
        {otpSentMessage && (
          <div style={{ color: 'green' }}>{otpSentMessage}</div>
        )}
        {stage === 'email' && (
          <Button variant="primary" onClick={handleSendOTP}>
            Send OTP
          </Button>
        )}

        <br />

        {stage === 'otp' && (
          <>
            <FormControl
              type="text"
              placeholder="Enter OTP"
              value={enterOtpValue}
              onChange={(e) => setEnterOtpValue(e.target.value)}
              className="mb-2"
            />
            <Button variant="success" onClick={handleOTPVerification} className="mt-2">
              Verify OTP
            </Button>
          </>
        )}

        {otpVerificationMessage && (
          <>
            <div style={{ color: 'green' }}>{otpVerificationMessage}</div>
            <p style={{ color: 'purple' }}>Click the button below to activate your account</p>
            <Button onClick={handleActive}>Activate</Button>
          </>
        )}

        <br />

        {errorMessageModal && <div className="text-danger mt-2">{errorMessageModal}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActivataionAccount
