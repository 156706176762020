import { faBan, faBell, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CustomNavbar from './CustomNavbar'
import DeactivateAccountContent from './DeactivateAccountContent'
import Footer from './Footer'
import NotificationContent from './NotificationContent'
import PersonalContent from './PersonalContent'
import './settings.css'

const Settings = () => {
    const [activeTab, setActiveTab] = useState("personal");

    const renderContent = () => {
        switch (activeTab) {
            case "personal":
                return <PersonalContent />;
            case "notification":
                return <NotificationContent />;
           
            case "deactivate":
                return <DeactivateAccountContent />;
            default:
                return <div>Select a tab to view content.</div>;
        }
    };
    return (
        <div className="main-content" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div className="custom-navbar-container">
                <CustomNavbar />
            </div>

            {/* Main content */}
            <div className='welcome-msg'>
                {/* Your main content goes here */}
                <h3 className='text-center'>Settings</h3>

                <Container>
                    <hr style={{ width: '100%', borderBottom: '1px solid black', marginLeft: '30px' }}></hr>
                    <Row>
                        {/* Vertical Tabs */}
                        <Col md={2} className="profile-tabs" style={{fontSize:'20px'}}>
                            <div
                                onClick={() => setActiveTab("personal")}
                                className={`tab-item ${activeTab === "personal" ? "active-tab" : ""}`}
                            >
                                <FontAwesomeIcon icon={faUser} /> Personal
                            </div>
                            <div
                                onClick={() => setActiveTab("notification")}
                                className={`tab-item ${activeTab === "notification" ? "active-tab" : ""}`}
                            >
                                <FontAwesomeIcon icon={faBell} /> Notification
                            </div>
                        
                            <div
                                onClick={() => setActiveTab("deactivate")}
                                className={`tab-item ${activeTab === "deactivate" ? "active-tab" : ""}`}
                            >
                                <FontAwesomeIcon icon={faBan} /> Deactivate Account
                            </div>
                        </Col>

                        {/* Tab Content */}
                        <Col md={9}>
                            <div className="tab-content">
                                {renderContent()}
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* Footer section */}
                <div style={{ marginTop: '400px' }}>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Settings
