// import React, { useState } from 'react';
// import { Button, Card, Col, Form, Row } from 'react-bootstrap';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import api from '../../apiClient';
// import ActivataionAccount from './ActivataionAccount';
// import SocialButtons from './sessions/SocialButtons';

// const ForgetPassword = () => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const redirectPath = location.state?.redirectPath || ' '; // Default to home if no path is provided

//     const [userEmail, setEmail] = useState();
//     const [errorMessage, setErrorMessage] = useState('');
//     const [successMessage, setSuccessMessage] = useState('');
//     const [otp, setOtp] = useState();
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [stage, setStage] = useState('email');
//     const [otpEnter, setOtpEnter] = useState();
//     const [userDeactivtaeErrorMsg, setUserDeactivateErrorMsg] = useState('')
//     const [passwordMatchError, setPasswordMatchError] = useState(false);
//     const [showActivationModal, setShowActivationModal] = useState(false); // State to control modal visibility
//     const [passwordCriteriaError, setPasswordCriteriaError] = useState(false);
//     console.log(redirectPath)
//     const handleEmailSubmit = async (e) => {
//         console.log("userEmail -- > " + userEmail);

//         // Check if the user exists with the provided email
//         const checkUserResponse = await api.checkUser(userEmail);
//         console.log("User Check Response:", checkUserResponse.data);

//         if (checkUserResponse.status === 200) {
//             if (checkUserResponse.data.deActivate === true) {
//                 // Account is deactivated, show message to activate the account
//                 // setEmail(userEmail);
//                 setUserDeactivateErrorMsg(
//                     <span>
//                         Your email already exists. Please{' '}
//                         <Link
//                             to="#"
//                             onClick={(e) => {
//                                 e.preventDefault(); // Prevent default link behavior
//                                 localStorage.setItem('redirectAfterLogin', 'forget-password');
//                                 setShowActivationModal(true); // Show the activation modal
//                             }}
//                         >
//                             Activate Your Account
//                         </Link>{' '}
//                         to log in to your account and then proceed to reset your password.
//                     </span>
//                 );
//             } else {
//                 // Account is active, proceed to reset password
//                 try {
//                     const response = await api.otpGenerate(userEmail);
//                     if (response.data) {
//                         setOtp(response.data);
//                         setStage('otp'); // Proceed to OTP stage
//                     } else {
//                         setErrorMessage("InvalidEmail");
//                     }
//                 } catch (error) {
//                     console.log(error);
//                 }
//             }
//         } else {
//             // Email does not exist, prompt to register
//             setUserDeactivateErrorMsg(
//                 <span>
//                     This email does not exist. Please{' '}
//                     <Link
//                         to="/" // Link to the registration page
//                     >
//                         Register yourself
//                     </Link>{' '}
//                     first.
//                 </span>
//             );
//         }
//     };

//     const handleOtpSubmit = (e) => {
//         console.log("userEmail -- > " + userEmail);
//         e.preventDefault();
//         console.log(otp);
//         console.log(otpEnter)
//         if (otp == otpEnter)
//             setStage('resetPassword');
//         else
//             setErrorMessage("Invalid otp");
//     };


//     const handlePasswordReset = async (e) => {
//         e.preventDefault();
//         console.log("userEmail -- > " + userEmail);
//         if (!validatePassword()) {
//             return;
//         }
//         try {
//             // Pass userEmail and newPassword as separate arguments, not as an object
//             const response = await api.updatePassword(userEmail, newPassword);
//             if (response.data) {
//                 setSuccessMessage("Password reset successfully! Now You can login with your new password.");
                
//             } else {
//                 setErrorMessage('');
//                 setPasswordMatchError(false);
//                 setPasswordCriteriaError(false);
//             }
//         }
//         catch (error) {
//             console.log(error);
//         }
//     };
//     const validatePassword = () => {
//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,12}$/;
//         const isValidPassword = passwordRegex.test(newPassword) && newPassword === confirmPassword;

//         if (!isValidPassword) {
//             if (newPassword !== confirmPassword) {
//                 setPasswordMatchError(true);
//             } else {
//                 setPasswordCriteriaError(true);
//             }
//             return false;
//         }
//         return true;
//     };


//     return (
//         <div className="auth-layout-wrap">
//             <div className="auth-content">
//                 <Card className="o-hidden">
//                     <Row>
//                         <Col md={6}>
//                             <div className="p-4">

//                                 <h1 className="mb-3 text-18">Forget Password</h1>
//                                 <div className='forgetpassword-container'>
//                                     {passwordMatchError && (
//                                         <p className="error-message">Password and confirm password do not match</p>
//                                     )}

//                                     {passwordCriteriaError && (
//                                         <p className="error-message">Password should include at least one number, one special character, one capital letter, one small letter, and have a length between 8 to 12 characters</p>
//                                     )}
//                                     {stage === 'email' && (
//                                         <Form onSubmit={handleEmailSubmit}>
//                                             <Form.Group className="mb-3" controlId="email">
//                                                 <Form.Label>Enter your email:</Form.Label>
//                                                 <Form.Control
//                                                     type="email"
//                                                     value={userEmail}
//                                                     onChange={(e) => setEmail(e.target.value)}
//                                                     required
//                                                 />
//                                             </Form.Group>

//                                             <Button variant="primary" type="submit">
//                                                 Submit
//                                             </Button>
//                                         </Form>
//                                     )}
//                                     {userDeactivtaeErrorMsg && <div className="text-danger">{userDeactivtaeErrorMsg}</div>}

//                                     {stage === 'otp' && (
//                                         <Form onSubmit={handleOtpSubmit}>
//                                             <Form.Group className="mb-3" controlId="otp">
//                                                 <Form.Label>Enter OTP:</Form.Label>
//                                                 <Form.Control
//                                                     type="number"
//                                                     value={otpEnter}
//                                                     onChange={(e) => setOtpEnter(e.target.value)}
//                                                     required
//                                                 />
//                                             </Form.Group>

//                                             <Button variant="primary" type="submit">
//                                                 Submit
//                                             </Button>

//                                             {errorMessage && <div className="error-message mt-2">{errorMessage}</div>}
//                                         </Form>
//                                     )}

//                                     {stage === 'resetPassword' && (
//                                         <Form onSubmit={handlePasswordReset}>
//                                             <Form.Group className="mb-3" controlId="newPassword">
//                                                 <Form.Label>New Password:</Form.Label>
//                                                 <Form.Control
//                                                     type="password"
//                                                     value={newPassword}
//                                                     onChange={(e) => setNewPassword(e.target.value)}
//                                                     required
//                                                 />
//                                             </Form.Group>

//                                             <Form.Group className="mb-3" controlId="confirmPassword">
//                                                 <Form.Label>Confirm Password:</Form.Label>
//                                                 <Form.Control
//                                                     type="password"
//                                                     value={confirmPassword}
//                                                     onChange={(e) => setConfirmPassword(e.target.value)}
//                                                     required
//                                                 />
//                                             </Form.Group>

//                                             <Button variant="primary" type="submit">
//                                                 Reset Password
//                                             </Button>

//                                             {successMessage && (
//     <div className="success-message mt-2">
//         {successMessage}
//         <br />
//         <Link to={redirectPath} className="btn btn-link">Login</Link> {/* Add a login link */}
//     </div>
// )}
//                                         </Form>
//                                     )}
//                                 </div>
//                             </div>
//                             {showActivationModal && (
//                                 <ActivataionAccount
//                                     userEmail={userEmail}
//                                     onClose={() => setShowActivationModal(false)} // Pass a function to close the modal
//                                 />
//                             )}
//                         </Col>
//                         <Col md={6} className="text-center auth-cover">
//                             <div className="pe-3 auth-right">
//                                 <div className="auth-logo text-center mb-4">
//                                     <img src="/jb_logo.png" alt="JobDB" style={{ height: '100px', width: '250px' }} />
//                                 </div>

//                                 <SocialButtons
//                                     routeUrl="/signup"
//                                     googleHandler={() => alert("google")}
//                                     facebookHandler={() => alert("facebook")}
//                                 />
//                             </div>
//                         </Col>
//                     </Row>
//                 </Card>
//             </div>
//         </div>
//     )
// }

// export default ForgetPassword




import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import api from '../../apiClient';
import ActivataionAccount from './ActivataionAccount';
import SocialButtons from './sessions/SocialButtons';

const ForgetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const redirectPath = location.state?.redirectPath || '/'; // Default to '/' home if no path is provided

    const [userEmail, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [otp, setOtp] = useState();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [stage, setStage] = useState('email');
    const [otpEnter, setOtpEnter] = useState('');
    const [userDeactivateErrorMsg, setUserDeactivateErrorMsg] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState(false);
    const [showActivationModal, setShowActivationModal] = useState(false);
    const [passwordCriteriaError, setPasswordCriteriaError] = useState(false);

    const handleEmailSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        console.log("userEmail -- > " + userEmail);

        // Check if the user exists with the provided email
        const checkUserResponse = await api.checkUser (userEmail);
        console.log("User  Check Response:", checkUserResponse.data);

        if (checkUserResponse.status === 200) {
            if (checkUserResponse.data.deActivate === true) {
                // Account is deactivated, show message to activate the account
                setUserDeactivateErrorMsg(
                    <span>
                        Your email already exists. Please{' '}
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                localStorage.setItem('redirectAfterLogin', 'forget-password');
                                setShowActivationModal(true); // Show the activation modal
                            }}
                        >
                            Activate Your Account
                        </Link>{' '}
                        to log in to your account and then proceed to reset your password.
                    </span>
                );
            } else {
                // Account is active, proceed to reset password
                try {
                    const response = await api.otpGenerate(userEmail);
                    if (response.data) {
                        setOtp(response.data);
                        setStage('otp'); // Proceed to OTP stage
                    } else {
                        setErrorMessage("Invalid Email");
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            // Email does not exist, prompt to register
            setUserDeactivateErrorMsg(
                <span>
                    This email does not exist. Please{' '}
                    <Link to="/">Register yourself</Link> first.
                </span>
            );
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        console.log("userEmail -- > " + userEmail);
        console.log(otp);
        console.log(otpEnter);
        if (otp == otpEnter) {
            setStage('resetPassword');
        } else {
            setErrorMessage("Invalid OTP");
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        console.log("userEmail -- > " + userEmail);
        if (!validatePassword()) {
            return;
        }
        try {
            const response = await api.updatePassword(userEmail, newPassword);
            if (response.data) {
                setSuccessMessage("Password reset successfully! Now you can login with your new password.");
            } else {
                setErrorMessage('');
                setPasswordMatchError(false);
                setPasswordCriteriaError(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,12}$/;
        const isValidPassword = passwordRegex.test(newPassword) && newPassword === confirmPassword;

        if (!isValidPassword) {
            if (newPassword !== confirmPassword) {
                setPasswordMatchError(true);
            } else {
                setPasswordCriteriaError(true);
            }
            return false;
        }
        return true;
    };

    return (
        <div className="auth-layout-wrap">
            <div className="auth-content">
                <Card className="o-hidden">
                    <Row>
                        <Col md={6}>
                            <div className="p-4">
                                <h1 className="mb-3 text-18">Forget Password</h1>
                                <div className='forgetpassword-container'>
                                    {passwordMatchError && (
                                        <p className="error-message">Password and confirm password do not match</p>
                                    )}
                                    {passwordCriteriaError && (
                                        <p className="error-message">Password should include at least one number, one special character, one capital letter, one small letter, and have a length between 8 to 12 characters</p>
                                    )}
                                    {stage === 'email' && (
                                        <Form onSubmit={handleEmailSubmit}>
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>Enter your email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={userEmail}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    )}
                                    {userDeactivateErrorMsg && <div className="text-danger">{userDeactivateErrorMsg}</div>}
                                    {stage === 'otp' && (
                                        <Form onSubmit={handleOtpSubmit}>
                                            <Form.Group className="mb-3" controlId="otp">
                                                <Form.Label>Enter OTP:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={otpEnter}
                                                    onChange={(e) => setOtpEnter(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                            {errorMessage && <div className="error-message mt-2">{errorMessage}</div>}
                                        </Form>
                                    )}
                                    {stage === 'resetPassword' && (
                                        <Form onSubmit={handlePasswordReset}>
                                            <Form.Group className="mb-3" controlId="newPassword">
                                                <Form.Label>New Password:</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="confirmPassword">
                                                <Form.Label>Confirm Password:</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                Reset Password
                                            </Button>
                                            {successMessage && (
                                                <div className="success-message mt-2">
                                                    {successMessage}
                                                    <br />
                                                    <Link to={redirectPath} className="btn btn-link">Login</Link>
                                                </div>
                                            )}
                                        </Form>
                                    )}
                                </div>
                            </div>
                            {showActivationModal && (
                                <ActivataionAccount
                                    userEmail={userEmail}
                                    onClose={() => setShowActivationModal(false)}
                                />
                            )}
                        </Col>
                        <Col md={6} className="text-center auth-cover">
                            <div className="pe-3 auth-right">
                                <div className="auth-logo text-center mb-4">
                                    <img src="/jb_logo.png" alt="JobDB" style={{ height: '100px', width: '250px' }} />
                                </div>
                                <SocialButtons
                                    routeUrl="/signup"
                                    googleHandler={() => alert("google")}
                                    facebookHandler={() => alert("facebook")}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    );
}

export default ForgetPassword;
