import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Table, Button, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Footer from '../../pages/Footer';
import Pagination from '../../Pagination';
import './CandidateDashboard.css';
import DashboardLayout from './DashboardLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBuildingCircleArrowRight, faBuildingCircleCheck, faBuildingColumns, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const CandidatesCompanies = () => {
  const BASE_API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const userName = location.state?.userName;
  const userId = location.state?.userId;

  // Retrieve appliedCompany from location.state
  const appliedCompany = location.state?.appliedCompany;

  // Initialize `key` from localStorage or fall back to the appliedCompany state
  const storedTabKey = localStorage.getItem('activeCompanyTabKey');
  const [key, setKey] = useState(appliedCompany ? 'appliedCompany' : (storedTabKey ? storedTabKey : 'all'));
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);
  const [totalPages, setTotalPages] = useState(0);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(' ');

  const navigate = useNavigate();

  // Store the active tab in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('activeCompanyTabKey', key);
  }, [key]);

  // Fetch data based on tab selection and other dependencies
  useEffect(() => {
    if (key === 'all') {
      fetchData();
    } else if (key === 'appliedCompany') {
      fetchDataByAppliedCompanies();
    }
  }, [key, search, page, pageSize, sortOrder, sortedColumn]);

  const fetchDataByAppliedCompanies = async () => {
    try {
      const url = search ? `${BASE_API_URL}/searchAppliedCompanies` : `${BASE_API_URL}/appliedCompanies`;
      const params = {
        search,
        userId: userId,
        page: page,
        size: pageSize,
        sortBy: sortedColumn,
        sortOrder: sortOrder,
      };
      const appliedCompanies = await axios.get(url, { params });
      setCompanies(appliedCompanies.data.content);
      setTotalPages(appliedCompanies.data.totalPages);
    } catch (error) {
      console.log("Error fetching data: " + error);
    }
  };

  const fetchData = async () => {
    try {
      const url = search ? `${BASE_API_URL}/searchCompany` : `${BASE_API_URL}/companiesList`;
      const params = {
        search,
        page: page,
        size: pageSize,
        sortBy: sortedColumn,
        sortOrder: sortOrder,
      };
      const response = await axios.get(url, { params });
      if (response.data.content.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Company not found!"
        });
        setSearch('');
      }
      setCompanies(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log("Error fetching data: " + error);
    }
  };

  const handleSort = (column) => {
    let order = 'asc';
    if (sortedColumn === column) {
      order = sortOrder === 'asc' ? 'desc' : 'asc';
    }
    setSortedColumn(column);
    setSortOrder(order);
  };

  const handlePageClick = (data) => {
    const selectedPage = Math.max(0, Math.min(data.selected, totalPages - 1));
    setPage(selectedPage);
  };

  const handlePageSizeChange = (e) => {
    const size = parseInt(e.target.value);
    setPageSize(size);
    setPage(0);
  };

  const handleClick = (companyId) => {
    const company = companies.find((company) => company.companyId === companyId);
    if (company) {
      const encodedCompanyName = encodeURIComponent(company.companyName); // Encode the company name
      navigate(`/candidate-dashboard/companies/companyPage/companyName/${encodedCompanyName}`, { state: { companyId, userName, userId } });
    } else {
      console.error("Company not found!");
    }
  };
  const user = { userName, userId };
  const isLastPage = page === totalPages - 1;
  const isPageSizeDisabled = isLastPage;
  return (
    <DashboardLayout>
      <div className="main-content">
        <Col md={4}>
          <h2 className='text-start' style={{ paddingLeft: '12px' }}>Companies</h2>
        </Col>
        <Row>
          <Col className='filter-action' style={{ paddingTop: '10px', marginLeft: '12px', marginRight: '12px' }}>
            <Tabs
              id="company-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)} // Change active tab on select
              className="mb-3"
            >
              <Tab eventKey="all" title={<><FontAwesomeIcon icon={faBuildingColumns} /> All Companies</>}>
                {/* Tab content */}
              </Tab>

              <Tab eventKey="appliedCompany" title={<><FontAwesomeIcon icon={faBuildingCircleCheck} /> Applied Companies</>}>
                {/* Tab content */}
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Row>
        <Col> </Col>
        <Col md={3} className="d-flex align-items-right" >
            <div className="search-bar" style={{ flex: 1 }}>
              <input
               style={{ borderRadius: '6px', height: '40px', width: '100%' }}
                type="text"
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </Col></Row>
        <br />
        {companies.length > 0 ? (
          <div className='table-details-list table-wrapper'>
            <Table hover className='text-center'>
              <thead className="table-light">
                <tr>
                  <th scope="col" onClick={() => handleSort('companyName')} style={{ cursor: 'pointer' }}>
                    Company Name{' '}
                    <span>
                      <span style={{ color: sortedColumn === 'companyName' && sortOrder === 'asc' ? 'black' : 'gray' }}>↑</span>{' '}
                      <span style={{ color: sortedColumn === 'companyName' && sortOrder === 'desc' ? 'black' : 'gray' }}>↓</span>
                    </span>
                  </th>
                  <th scope="col" onClick={() => handleSort('industryService')} style={{ cursor: 'pointer' }}>
                    Industry{' '}
                    <span>
                      <span style={{ color: sortedColumn === 'industryService' && sortOrder === 'asc' ? 'black' : 'gray' }}>↑</span>{' '}
                      <span style={{ color: sortedColumn === 'industryService' && sortOrder === 'desc' ? 'black' : 'gray' }}>↓</span>
                    </span>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company) => (
                  <tr key={company.companyId}>
                    <td>{company.companyName}</td>
                    <td>{company.industryService}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleClick(company.companyId)}>
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-bubble spinner-bubble-primary m-5" />
            <span>Loading...</span>
          </div>
        )}

        {companies.length > 0 && (
          <Pagination
            page={page}
            pageSize={pageSize}
            totalPages={totalPages}
            handlePageSizeChange={handlePageSizeChange}
            isPageSizeDisabled={isPageSizeDisabled}
            handlePageClick={handlePageClick}
          />
        )}
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default CandidatesCompanies;
