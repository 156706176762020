import React from 'react';
import { Button } from 'react-bootstrap';

const NotificationContent = () => {
    return (
        <div>
            <h4>Notification Settings</h4>
            <p>Manage your notification preferences below to stay updated with important updates and alerts.</p>

            <h5>Email Notifications</h5>
            <ul>
                <li>
                    <strong>Job Alerts:</strong> Receive notifications about new job postings that match your profile.
                </li>
                <li>
                    <strong>Application Updates:</strong> Stay informed about the status of your job applications.
                </li>
                <li>
                    <strong>System Notifications:</strong> Get updates about system changes or maintenance schedules.
                </li>
            </ul>

            <h5>Push Notifications</h5>
            <ul>
                <li>
                    <strong>Real-Time Updates:</strong> Enable push notifications for immediate updates on job statuses and messages.
                </li>
                <li>
                    <strong>Reminders:</strong> Get reminders about upcoming application deadlines.
                </li>
            </ul>

            <h5>Notification Preferences</h5>
            <form>
                <label>
                    <input type="checkbox" defaultChecked /> Receive email notifications
                </label>
                <br />
                <label>
                    <input type="checkbox" /> Enable push notifications
                </label>
                <br />
                <label>
                    <input type="checkbox" defaultChecked /> Receive promotional updates
                </label>
            </form>
            <Button>Update</Button>
{/* 
            <h5>Customize Notifications</h5>
            <p>
                Customize your preferences further by visiting the <a href="/settings">Settings</a> page.
            </p> */}
        </div>
    );
};

export default NotificationContent;
