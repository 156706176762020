import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, FormGroup } from 'react-bootstrap';
import { CgWebsite } from "react-icons/cg";
import { FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { PiLinkSimpleHorizontalFill } from "react-icons/pi";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const SocialProfileLinks = () => {
    const BASE_API_URL = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const userId = location.state?.userId || ""; // Access userId from state
    const [socialProfiles, setSocialProfiles] = useState([]);
    const [showForm, setShowForm] = useState(false); // Show form only if no profiles exist
    const [formData, setFormData] = useState({ socialProfileName: '', profileLink: '' });
    const [editingProfile, setEditingProfile] = useState(null);
    const formRef = useRef(null); // Create a ref for the form

    useEffect(() => {
        // Fetch existing social profiles for the user
        const fetchSocialProfiles = async () => {
            try {
                const response = await axios.get(`${BASE_API_URL}/ListOfSocialProfileLinks?userId=${userId}`);
                setSocialProfiles(response.data);
                // Show form only if no profiles exist
                if (response.data.length === 0) {
                    setShowForm(true);
                }
                console.log(response.data)
            } catch (error) {
                console.error("Error fetching social profiles:", error);
            }
        };

        fetchSocialProfiles();
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = async () => {
        if (editingProfile) {
            // Update existing profile
            try {
                const response = await axios.put(`${BASE_API_URL}/updateSocialProfile?socialProfileId=${editingProfile.socialProfileId}`, {
                    userId: userId,
                    socialProfileName: formData.socialProfileName,
                    profileLink: formData.profileLink
                });
                setSocialProfiles(socialProfiles.map(profile => profile.socialProfileId === editingProfile.socialProfileId ? response.data : profile));
                setShowForm(false);
                setFormData({ socialProfileName: '', profileLink: '' });
                setEditingProfile(null);
            } catch (error) {
                console.error("Error updating social profile link:", error);
            }
        } else {
            // Save new profile
            try {
                const response = await axios.post(`${BASE_API_URL}/social-profile-links`, {
                    userId: userId,
                    socialProfileName: formData.socialProfileName,
                    profileLink: formData.profileLink
                });
                setSocialProfiles([...socialProfiles, response.data]);
                setShowForm(false);
                setFormData({ socialProfileName: '', profileLink: '' });
            } catch (error) {
                console.error("Error saving social profile link:", error);
            }
        }
    };

    const handleEdit = (profile) => {
        setFormData({
            socialProfileName: profile.socialProfileName,
            profileLink: profile.profileLink
        });
        setEditingProfile(profile);
        setShowForm(true);
    };

    const handleDelete = async () => {
        if (editingProfile) {
            console.log(editingProfile.socialProfileId);
            try {
                // Call the delete API
                await axios.delete(`${BASE_API_URL}/deleteSocialProfile?socialProfileId=${editingProfile.socialProfileId}`);
                
                // Update the state to remove the deleted profile
                setSocialProfiles(socialProfiles.filter(profile => profile.socialProfileId !== editingProfile.socialProfileId));
                setShowForm(false);
                setFormData({ socialProfileName: '', profileLink: '' });
                setEditingProfile(null);
    
                // Show success message
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: `${editingProfile.socialProfileName} has been deleted.`,
                    confirmButtonText: 'OK'
                });
            } catch (error) {
                console.error("Error deleting social profile link:", error);
                // Optionally show an error message
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'There was an error deleting the social profile link.',
                    confirmButtonText: 'OK'
                });
            }
        }
    };

    const handleAddProfileClick = () => {
       
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setShowForm(true);
    };


    const getIcon = (name) => {
        switch (name.toLowerCase()) {
            case 'linkedin':
                return <FaLinkedin color="#0077B5" />; // LinkedIn color
            case 'github':
                return <FaGithub color="#333" />; // GitHub color
            case 'twitter':
                return <FaTwitter color="#1DA1F2" />; // Twitter color
            case 'facebook':
                return <FaFacebook color="#1877F2" />; // Facebook color
            case 'instagram':
                return <FaInstagram color="#E4405F" />; // Instagram color
            case 'portfolio':
                return <CgWebsite color="#4CAF50" />; // Portfolio color
            default:
                return <PiLinkSimpleHorizontalFill color="#007bff" />; // Default to primary color
        }
    };

    return (
        <div ref={formRef} className="candidate-profile-details">
            <h4>Social profile presence:</h4>
            <Col lg={6} className="mb-4">
                {socialProfiles.length === 0 || showForm ? (
                    // Show form if no profiles exist
                    <form >
                        <Card>
                            <Card.Body>
                                <FormGroup controlId="name" className="row mb-3">
                                    <Col md={4}>
                                        <Form.Label className="action-bar-horizontal-label">Social profile name:</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="socialProfileName"
                                            value={formData.socialProfileName}
                                            onChange={handleInputChange}
                                            placeholder="(Eg: LinkedIn, GitHub...etc)"
                                        />
                                        <Form.Text className="ul-form__text">Please Enter social profile name</Form.Text>
                                    </Col>
                                </FormGroup>

                                <FormGroup controlId="link" className="row mb-3">
                                    <Col md={4}>
                                        <Form.Label className="action-bar-horizontal-label">Link:</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="text"
                                            name="profileLink"
                                            value={formData.profileLink}
                                            onChange={handleInputChange}
                                            placeholder="Enter your profile link"
                                        />
                                        <Form.Text className="ul-form__text">Please enter link of your social profile</Form.Text>
                                    </Col>
                                </FormGroup>
                            </Card.Body>

                            <Card.Footer>
                                <button type="button" className="btn btn-primary m-1" onClick={handleSave}>
                                    Save
                                </button>
                                <button type="button" className="btn btn-outline-secondary m-1" onClick={() => setShowForm(false)}>
                                    Cancel
                                </button>
                                {editingProfile && (
                                    <button type="button" className="btn btn-danger m-1" onClick={handleDelete}>
                                        Delete
                                    </button>
                                )}
                            </Card.Footer>
                        </Card>
                    </form>
                ) : (
                    // Show existing profiles if they exist
                    <>
                        {socialProfiles.map(profile => (
                            <Card key={profile.socialProfileId} className="mb-2">
                                <Card.Body className="d-flex align-items-center">
                                    <div className="ml-2">
                                        <h5>{profile.socialProfileName} {getIcon(profile.socialProfileName)}</h5>
                                        <a href={profile.profileLink} target="_blank" rel="noopener noreferrer">{profile.profileLink}</a>
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={() => handleEdit(profile)} // Pass the profile to handleEdit
                                        >
                                            <MdEdit />
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                        <button type="button" className="btn btn-primary" onClick={handleAddProfileClick}>
                            Add Social Profile
                        </button>
                    </>
                )}
            </Col>
        </div>
    );
};

export default SocialProfileLinks;