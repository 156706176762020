import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './Slider.css'; // Ensure your CSS file is imported

const Slider = ({ initialStatus, onChangeStatus }) => {
  const [selectedValue, setSelectedValue] = useState('Not Seen');

  useEffect(() => {
    if (initialStatus) {
      setSelectedValue(initialStatus);
    }
  }, [initialStatus]);

  const handleSelect = (newValue) => {
    setSelectedValue(newValue);
    onChangeStatus(newValue);
  };

  // Determine the button class based on the selected value
 // Determine the button class based on the selected value
 const getButtonClass = () => {
  switch (selectedValue) {
    case 'Not Seen':
      return 'btn-light'; // Custom class for gray
    case 'Not Shortlisted':
      return 'btn-danger'; // Bootstrap class for red
    case 'Shortlisted':
      return 'btn-success'; // Bootstrap class for green
    default:
      return 'btn-gray'; // Default to gray if no match
  }
};
  return (
    <div className="btn-group">
      <Dropdown>
        <Dropdown.Toggle as="div" className="toggle-hidden">
          <button className={`btn ${getButtonClass()} text-white custom-btn btn-sm dropdown-toggle`} type="button">
            {selectedValue}
          </button>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleSelect("Not Seen")}>
            <span className="ul-task-manager__dot bg-light me-2"></span>
            Not Seen
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect("Not Shortlisted")}>
            <span className="ul-task-manager__dot bg-danger me-2"></span>
            Not Shortlisted
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleSelect("Shortlisted")}>
            <span className="ul-task-manager__dot bg-success me-2"></span>
            Shortlisted
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Slider;