import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FaAddressBook, FaBriefcase } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Footer from '../../pages/Footer';
import DashboardLayout from "./DashboardLayout ";
import EvergreenJobs from './EvergreenJobs';
import PostedJobs from './PostedJobs';

// Assuming customTabHeader is a helper function that renders tab headers with icons
const customTabHeader = (title, icon) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ marginLeft: '8px' }}>{title}</span>
    </div>
);

export const AllJobs = () => {
    // Initialize state for active tab, try to load from localStorage
    const location = useLocation();
    const isTotslJobs = location.state ? location.state.totalJobs : undefined;
    console.log(isTotslJobs);
    const storedTab = localStorage.getItem('activeJobTab');
    // Set activeTab based on the condition
    const [activeTab, setActiveTab] = useState(
        isTotslJobs ? 'regularJobs' : (storedTab ? storedTab :'regularJobs')
    );
    //  const [activeTab, setActiveTab] = useState(storedTab || 'regularJobs');

    // Function to handle tab selection and update state
    const handleTabClick = (key) => {
        setActiveTab(key);
        localStorage.setItem('activeJobTab', key);  // Store active tab in localStorage
    };

    useEffect(() => {
        // Check for a stored active tab in localStorage when the component mounts
        const storedTab = localStorage.getItem('activeJobTab');
        if (storedTab) {
            setActiveTab(storedTab);
        }
    }, []);
    return (
        <DashboardLayout>
            <Col md={4}>
                <h2 className='text-start' style={{  marginLeft: '12px' }}>Posted Jobs</h2>
            </Col>
            <Row>
                <Col style={{ marginLeft: '12px' }}>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={handleTabClick}
                        style={{ marginBottom: '20px' }}
                    >
                        <Tab
                            eventKey="regularJobs"
                            title={customTabHeader("Regular Jobs", <FaAddressBook />)}
                        >
                            <PostedJobs />
                        </Tab>
                        <Tab
                            eventKey="evergreenJobs"
                            title={customTabHeader("Evergreen Jobs", <FaBriefcase />)}
                        >
                            <EvergreenJobs />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Footer />
        </DashboardLayout>
    );
};
